<template>
  <div class="coinater">
    <div class="fdleft" id="table_content">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">形式审查</div>
          <div class="fdtsec">{{form.name}}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">能效标识申报指标情况表</div>
        <div class="fdltxt">
          <div class="tableform">
            <el-form ref="form" :model="form" label-width="180px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目名称：">
                        {{ form.name }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="申报星级：">
                        <i
                          v-for="item in form.stars"
                          :key="item"
                          class="el-icon-star-on"
                        ></i>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-form ref="formtable" :model="formtable" label-width="180px">
            <div class="engerytable">
              <table width="100%" border="1" cellspacing="0">
                <tbody>
                  <!-- <tr>
      <th scope="col">项目名称</th>
      <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
    </tr>
    <tr>
      <th scope="row">申报星级</th>
      <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
    </tr> -->
                  <tr>
                    <th scope="row">申报指标</th>
                    <td scope="col" colspan="2">
                      <input v-model="formtable.sbIndex" type="text" />
                    </td>
                    <th scope="col" colspan="2">指标情况</th>
                    <td scope="col" colspan="3">
                      <input v-model="formtable.sbCircu" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">相对节能率</th>
                    <td scope="col" colspan="7">
                      {{ formtable.relateEnergy }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">节能率</th>
                    <td scope="col" colspan="2">
                      <input v-model="formtable.buildEnergyRatio" type="text" />
                    </td>
                    <th scope="col" colspan="2">节能标准</th>
                    <td scope="col" colspan="3">
                      <div style="text-align: center">
                        <el-radio-group v-model="formtable.radio">
                          <el-radio :label="1">50%</el-radio>
                          <el-radio :label="2">65%</el-radio>
                        </el-radio-group>

                        <!-- <el-radio v-model="formtable.radio" label="1">75%</el-radio> -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">基础项</th>
                    <td scope="col" colspan="7">
                      经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020标准要求，建筑节能率为{{
                        formtable.buildEnergyRatio
                      }}，测评合格。
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">规定项</th>
                    <td scope="col" colspan="7">
                      经测评，该项目规定项{{
                        formtable.strip
                      }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                      3964-2020规定要求；
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">选择项</th>
                    <td scope="col" colspan="7">
                      经测评，该项目选择项{{ formtable.strip }}条参评，加{{
                        formtable.grades
                      }}分；
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" rowspan="6">建筑热工性能</th>
                    <td>
                      屋面保温（示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）
                    </td>
                    <td><input v-model="formtable.keepwarm" type="text" /></td>
                    <td>屋面传热系数[W/(m2·K)]（提示：保留两位小数）</td>
                    <td colspan="4">
                      <input v-model="formtable.heattransfer" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      外墙保温（示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）
                    </td>
                    <td><input v-model="formtable.roofWarm" type="text" /></td>
                    <td>外墙传热系数[W/(m2·K)] （提示：保留两位小数）</td>
                    <td colspan="4">
                      <input v-model="formtable.outHeat" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">外窗型材（示例：铝合金窗（6 +16A+6））</td>
                    <td rowspan="4">
                      <input v-model="formtable.exterProfile" type="text" />
                    </td>
                    <td rowspan="2">
                      外窗传热系数[W/(m2·K)] （提示：保留两位小数，东/南/西/北）
                    </td>
                    <td>东</td>
                    <td><input v-model="formtable.outEaast" type="text" /></td>
                    <td>南</td>
                    <td><input v-model="formtable.outSouth" type="text" /></td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td><input v-model="formtable.outWest" type="text" /></td>
                    <td>北</td>
                    <td><input v-model="formtable.outNorth" type="text" /></td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      遮阳系数SC（提示：保留两位小数，东/南/西/北）
                    </td>
                    <td>东</td>
                    <td><input v-model="formtable.scEaast" type="text" /></td>
                    <td>南</td>
                    <td><input v-model="formtable.scSouth" type="text" /></td>
                  </tr>
                  <tr>
                    <td>西</td>
                    <td><input v-model="formtable.scWest" type="text" /></td>
                    <td>北</td>
                    <td><input v-model="formtable.scNorth" type="text" /></td>
                  </tr>
                  <tr>
                    <th scope="row">空调冷热源</th>
                    <td colspan="7">
                      {{ formtable.cold_hot }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">生活热水节能</th>
                    <td colspan="7">
                      <input v-model="formtable.lifewater" type="text" />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">单位建筑面积全年能耗（kWh/m2）</th>
                    <td colspan="7">
                      <input v-model="formtable.yearEnergy" type="text" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="fdright">
      <div class="fdtitlee">
        <div class="fdtsec">{{ form.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">审查意见</div>
        <div class="fdrtxt">
          <el-input
            disabled
            v-model="idea"
            placeholder="请输入内容"
            style="height: 513px; border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group v-model="pass" disabled>
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <el-button type="primary" @click="pre_page">上一页</el-button>
          <el-button
            type="primary"
            :disabled="form.status != 15 && form.status != 17"
            @click="saveProject"
            >保存</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { editTableProject, projectTableInfo } from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";

export default {
  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      formtable: {},
      form: {
        stars: 1,
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],

      photo: [],

      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],
      jizu5: [],
      jizu6: [],

      idea: "",
      pass: "",
      id: "",

      project: {},
    };
  },

  mounted() {
    let project_id = this.$route.query.project_id;
    if (project_id && project_id != "") {
      this.id = project_id;
      this.getInfo();
    }
  },

  methods: {
    goBack() {
      this.$router.push({
        path: "./ipImformation?id=" + this.id,
      });
    },

    getInfo() {
      let params = {
        id: this.id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        let project = {
          name: that.form.name,
          building_area: that.form.building_area,
          build_company: that.form.build_company,
          address: that.form.address,
          weather: that.form.weather,
          design_company: that.form.design_company,
          manger_company: that.form.manger_company,
          work_build: that.form.work_build,
        };
        that.project = project;
        let checks = res.data.checks;
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 4) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.form.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.form.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;

        if (that.form.status != 15 && that.form.status != 17) {
          that.$nextTick(function () {
            let table = document.getElementById("table_content");
            let inputs = table.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = true;
            }
          });
        }
      });
    },

    //上一页，保存
    pre_page() {
      this.$router.push({
        path: "/user_mark/ipreporttable??project_id=" + this.id,
      });
    },

    //增加 冷水（热泵）机组
    addjizu(which) {
      if (which == 1) {
        this.jizu.push({});
      } else if (which == 2) {
        this.jizu2.push({});
      } else if (which == 3) {
        this.jizu3.push({});
      } else {
        this.jizu4.push({});
      }
    },

    //图片上传成功
    photo_success(re, file, fileList) {
      console.log(fileList);
      this.photo = fileList.map((item) => {
        return item.response.data;
      });
    },

    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject() {
      let that = this;
      this.form.jizu = this.jizu;
      this.form.jizu2 = this.jizu2;
      this.form.jizu3 = this.jizu3;
      this.form.jizu4 = this.jizu4;
      this.form.jizu5 = this.jizu5;
      this.form.jizu6 = this.jizu6;
      this.form.detail = this.formtable;
      this.form.detail.id = undefined;
      this.form.project = this.project;
      let params = this.form;
      params.token = this.$store.state.token;
      params.project_id = params.id;
      params.project.created_at = undefined;
      params.project.updated_at = undefined;
      params.save = 4;

      if (params.files) {
        for (let i = 0; i < params.files.length; i++) {
          params.files[i].name = params.files[i].file;
        }
      }

      editTableProject(params)
        .then((res) => {
          console.log(res);
          // localStorage.removeItem('form')
          that.$message.success("保存成功");
          // that.$router.push({
          //   path: './ipImformation?id='+this.id
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea > textarea {
  height: 513px;
}
/deep/.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
th {
  height: 40px;
}
</style>
<style lang="scss" scoped>
.el-form-item__label {
  font-size: 16px;
}

.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 35px;
        height: 76vh;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
    .fdtsec {
      display: flex;
      flex-direction: row-reverse;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #666666;
    }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 27px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin-bottom: 34px;
      }

      .fdradio {
        margin: 15px 0;
        text-align: center;
      }

      .fdrbtn {
        // margin: 20px 0;
        text-align: center;
      }
    }
  }
}
.engerytable input {
  background-color: white;
}
</style>
